@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Pacifico|Source+Sans+Pro:400,700");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap');

@media print {
    @page {
        size: 210mm 297mm;
    }

    .nav-bar {
        display: none;
    }

    #top-nav {
        display: none;
    }
}

.print_pages {
    justify-content: center;
    width: 95%;
    height: 95%;
    page-break-after: always;
}

.print_pages:last-child {
    page-break-after: auto;
}

html {
    color: rgb(91, 87, 91);
    ;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 11pt;
    line-height: 1.6;
}

html body {
    margin: 0;
}

.flex-line {
    display: flex;
    justify-content: space-between;
}

html h1 {
    color: rgb(91, 87, 91);
    ;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 30pt;
    margin: 0;
}

html dl {
    text-align: left;
    position: relative;
    right: 0;
    top: 0;
}

html dl dt,
html dl dd {
    display: inline;
    margin: 0;
}

html dl dd {
    margin-left: 1rem;
}

html dl dt {
    color: #a9a;
}

html dl dt::before {
    content: '';
    display: block;
}

html dl dt::after {
    content: ':';
}

#address-shell {
    display: flex !important;
    justify-content: space-between !important;
    margin: 10px 0rem 100px 0rem;
}

html #address-shell address {
    font-family: 'Noto Sans JP', sans-serif;

}

html #address-shell address#to {
    color: rgb(91, 87, 91);
    ;
    flex: 1;
    margin: 0rem 1rem 0rem 1rem;
}

html #address-shell address#to .to-name {
    text-align: left;
    font-size: 1.3rem;
}

html #address-shell address#to .to-address {
    font-size: 1rem;
}

html #address-shell address#from {
    margin-left: auto;
    text-align: right;
}


#details {
    border-collapse: collapse;
    width: 100%;
}

#details th {
    border-bottom: 1px solid #a9a;
    color: #a9a;
    font-size: 10pt;
    font-weight: 400;
    padding-bottom: .25cm;
    text-transform: uppercase;
}

#details tbody {
    border-bottom: 1px solid #a9a;
}

#details .content-row {
    border-top: 1px solid #e0e0e0 !important;
}

#details td {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

#details td:last-of-type {
    color: rgb(91, 87, 91);
    ;
    font-weight: bold;
    text-align: right;
}

#details th,
#details td {
    text-align: center;
}

#details th:first-of-type,
#details td:first-of-type {
    text-align: left;
}

#details th:last-of-type,
#details td:last-of-type {
    text-align: right;
}

#details .amount-string {
    color: rgb(89, 177, 132);
    font-weight: bolder;
}

hr {
    color: #a9a
}

#total {
    border-collapse: collapse;
    width: 40%;
    font-size: 20pt;
    margin-top: 0.5rem;
    right: 1rem;
}

.total-shell {
    text-align: right;
    align-content: right;
    right: 0mm;
}

#total th {
    color: #a9a;
    font-size: 10pt;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
}

#total td {
    text-align: right;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
}

#total td:last-of-type {
    color: rgb(91, 87, 91);
    font-weight: bold;
}

#total .discounts td,
#total .discounts th {
    border-bottom: .3mm solid #a9a;
    padding-bottom: 0.5rem;
}

#total .total-price td,
#total .total-price th {
    padding-top: 0.8rem;
}

.payment-summary {
    align-content: right;
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
}

.payment-amount {
    font-weight: bold;
    font-size: 1.5rem;
}

.payment-method span {
    padding-left: 1rem;
    font-size: 1.1rem;
    font-weight: bold;
}

.subject {
    color: #a9a;
    margin-bottom: 2px;
}

.note-area {
    width: 100%;
    height: 10rem;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
}